import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
    _apiURI: string;

    constructor() {
        this._apiURI =
            //  'https://localhost:44359/api';
            // 'https://localhost:5001/api';
            // 'https://pctviewerapi/api';
            'https://pctviewerapi.bridges2.psc.edu/api';
            // 'https://mp23webappwinapi.azurewebsites.net/api';
    }

    getApiURI() {
        return this._apiURI;
    }
}
