import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from '../utils/config.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    baseUrl: string = '';

    constructor(private http: HttpClient, private configService: ConfigService) {
        this.baseUrl = configService.getApiURI();
    }

    login(username: string, password: string) {

        return this.http.post<any>(`${this.baseUrl}/User/`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.Token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('isLoggedin', JSON.stringify(user));
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('isLoggedin');
    }
  
}
