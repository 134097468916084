import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';


@Injectable({ providedIn: 'root' })
export class DateService  {

    datePipe = new DatePipe('en-US');
    constructor(private ngbDateParserFormatter: NgbDateParserFormatter) {}

    compareStartEndDates(date: NgbDateStruct,statdate: NgbDateStruct):boolean{
        if(new Date(this.formatForServer(date))<new Date(this.formatForServer(statdate)))
          return true;    
        else
          return false;
      }
    
      setngbPlannedEndDate(date: NgbDateStruct,contractDays:number):NgbDateStruct{
        if (date === null || date.toString() === '') {
          return this.ngbDateParserFormatter.parse('');
          return;
        }        
        const startDate = new Date(date.year, date.month - 1, date.day);
        const endDate = new Date(startDate.setDate(startDate.getDate()+contractDays));
        const endYear = endDate.getFullYear().toString();
        const endMonth = endDate.getMonth() + 1;
        const endDay = endDate.getDate();
    
        return this.ngbDateParserFormatter.parse(endYear + '-' +  endMonth + '-' + endDay);    
        
      }
    
      setngbDate(date: string): NgbDateStruct {
        if (date === null) {
          return this.ngbDateParserFormatter.parse(null);
        }
    
        if (date === '') {
          return this.ngbDateParserFormatter.parse('');
        }
    
        const startDate = new Date(date);
        const startYear = startDate.getFullYear().toString();
        const startMonth = startDate.getMonth() + 1;
        const startDay = startDate.getDate();
    
        return this.ngbDateParserFormatter.parse(startYear + '-' + startMonth.toString() + '-' + startDay);
      }
    
      formatForServer(date: NgbDateStruct): string {
        if (date == null) {
          return '';
        }
        try {
          return this.datePipe.transform(new Date(date.year, date.month - 1, date.day), 'y-MM-dd');
        } catch (e) {
          return '';
        }
      }
}
