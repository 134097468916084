import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../shared/utils/config.service';
import { Validators, ValidatorFn, ValidationErrors, FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class HelpService {
  baseUrl: string = '';
  constructor(private http: HttpClient, configService: ConfigService) {
    this.baseUrl = configService.getApiURI();
  }

  convert<T, TK extends keyof T>(arr: T[], idKey: TK, titleKey: TK): any[] {
    return arr.map(item => {
      return { id: item[idKey], itemName: item[titleKey] };
    });
  }

  elementTypeCodeValidator(code: string): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const errors: ValidationErrors = {};
      let elementTypeCodes: string[] = ['BS', 'CH', 'CL', 'EL', 'GA', 'LS', 'MC', 'PL', 'PW', 'RR', 'SP', 'XX', 'OR', 'HR'];
      let typeCode: string = control.get(code).value;
      if (typeCode == null) {
        typeCode = '';
        errors.InvalidCode = {
          message: 'empty Type code: ' + control.get(code).value
        };
      }
      if (elementTypeCodes.filter(x => x == typeCode.trim().toUpperCase()).length == 0) {
        errors.InvalidCode = {
          message: 'Invalid Element Type Code'
        };
      }
      return Object.keys(errors).length ? errors : null;
    };
  }

  elementVersionNoteValidator(prevNote: string, curNote: string, rowUpdated: string): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const errors: ValidationErrors = {};
      let prevVersionNote: string = control.get(prevNote).value;
      let curVersionNote: string = control.get(curNote).value;
      let isRowUpdated: boolean = control.get(rowUpdated).value;
      if (isRowUpdated != null && isRowUpdated) {
        if (curVersionNote == null) {
          curVersionNote = '';
          errors.InvalidNote = {
            message: 'Empty Version Note field: ' + control.get(curNote).value
          };
        }
        if (prevVersionNote == null) {
          prevVersionNote = '';
          // errors.InvalidNote = {            message: 'Empty Version Note field: ' + control.get(curNote).value          };
        }
        if (prevVersionNote == curVersionNote) {
          errors.InvalidCode = {
            message: 'Version Note must be updated'
          };
        }
      }
      return Object.keys(errors).length ? errors : null;
    };
  }

  gisTypeValidator(gisType: string): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const errors: ValidationErrors = {};
      let gisTypes: string[] = ['point', 'line', 'polygon', 'no gis', 'none'];
      let type: string = control.get(gisType).value;
      if (gisTypes.filter(x => x == type.trim().toLowerCase()).length == 0) {
        errors.InvalidGisType = {
          message: 'Invalid GIS Type'
        };
      }
      return Object.keys(errors).length ? errors : null;
    };
  }

  RegionValidator(region: string): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {
      const errors: ValidationErrors = {};
      //let regions:string[] = [ '001', '002', '003','03a', '03b', '004','005'];
      let regions: string[] = ['barataria', 'terrebonne', 'central coast', 'chenier plain', 'pontchartrain'];
      let record: string = control.get(region).value;
      if (regions.filter(x => x == record.trim().toLowerCase()).length == 0) {
        errors.InvalidRegion = {
          message: 'Invalid Region'
        };
      }
      return Object.keys(errors).length ? errors : null;
    };
  }
}
