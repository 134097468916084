import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { User } from '../../shared/models/entities';

@Injectable()
export class RoleGuard implements CanActivate {
    user: User = null;

    constructor(private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot): boolean {
        if (localStorage.getItem('isLoggedin')) {
            this.user = JSON.parse(localStorage.getItem('isLoggedin'));

           return true;
        }

        this.router.navigate(['/dashboard']);
        return false;
    }
}
