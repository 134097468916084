import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AuthGuard,RoleGuard } from './shared';
import { JwtInterceptor, ErrorInterceptor } from './shared/helpers';
import { ConfigService } from './shared/utils/config.service';
import { ExcelService } from './shared/services/excel.service';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSort,faFileAlt } from '@fortawesome/free-solid-svg-icons';
// import { PercentPipe } from '@angular/common';

@NgModule({
    imports: [
        FormsModule,
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: 'rgba(0,0,0,0.3)',
            backdropBorderRadius: '3px',
            primaryColour: '#0095d9',
            secondaryColour: '#045594',
            fullScreenBackdrop:true
        })
    ],
    declarations: [AppComponent],
    providers: [
        ConfigService,
        ExcelService,
        AuthGuard,
        RoleGuard,
        // PercentPipe,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private library: FaIconLibrary) {
        library.addIcons(faSort,faFileAlt);
      }
}
